<template lang="pug">
component.icon-button(
  :is="componentType",
  :to="effectiveTo",
  :href="effectiveHref",
  :class="{ disabled, plain, bordered }"
)
  .icon-button--icon
    ClientOnly(fallback-tag="i")
      fa(:icon="icon")
</template>
<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"
import { computed } from "vue"

const props = defineProps({
  plain: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Array<String>
  },
  bordered: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  internal: {
    type: Boolean,
    default: true,
  },
  to: {
    type: [ String, Object ] as PropType<RouteLocationRaw>,
  },
})

const componentType = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return resolveComponent('NuxtLink')
  } else {
    return 'a'
  }
})

const effectiveTo = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return props.to
  } else {
    return undefined
  }
})

const effectiveHref = computed(() => {
  if (!props.disabled && props.to && props.internal) {
    return undefined
  } else {
    return props.to
  }
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'

$extra-light-magenta: scale-color($magenta, $lightness: +65%)
$light-magenta: scale-color($magenta, $lightness: +15%)

.icon-button
  position: relative
  display: inline-block
  height: 40px
  width: 40px
  cursor: pointer
  border-radius: 50%
  border: 0 solid $magenta
  box-sizing: border-box
  background-color: $magenta
  color: white
  user-select: none

  &:hover
    background-color: $light-magenta
    border-color: $light-magenta

  &.plain
    background-color: white
    color: $magenta

    &.disabled
      background-color: white
      color: $extra-light-magenta
      border-color: $extra-light-magenta

      &:hover
        color: $extra-light-magenta
        border-color: $extra-light-magenta

    &:hover
      color: $light-magenta
      border-color: $light-magenta

  &.bordered
    border-width: 1px

  &.disabled
    cursor: default
    pointer-events: none
    background-color: $extra-light-magenta
    border-color: $extra-light-magenta

  .icon-button--icon
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    width: 100%
    font-size: 16px
    flex-shrink: 0
    color: inherit
</style>
<style lang="sass">
@import "~/assets/styles/variables.sass"

.icon-button
  .icon-button--icon
    *
      color: inherit
</style>